export const shipwreckData = [
  {
    cabin: "Shipwreck",
    airbnb: "https://www.airbnb.ca/rooms/19529407?guests=1&adults=1",
    about: "The Shipwreck is the perfect place to relax and rejuvenate in a totally private and comfortable setting. The Shipwreck is suitable for 1 or 2 guests and has all of the amenities to make your vacation a pleasurable experience as the Shipwreck is located just steps from the beautiful and famous Chesterman Beach. Amenities include high speed internet, and optic TV for those who wish to stay connected. Indoor & outdoor hot showers and a clawfoot bathtub in the solarium. Fully equipped kitchen and BBQ in the solarioum to cook your catch of the day. The Shipwreck cabin has west coast character built with recycled woods creating a warm and inviting atmosphere.",
    amenities: [
      {
        id: 1,
        amenity: "Cozy and cheerful open concept studio vacation cottage"
      },
      {
        id: 2,
        amenity: "Suitable for 1 or 2 guests including children over 5 years old"
      },
      {
        id: 3,
        amenity: "Queen-size bed"
      },
      {
        id: 4,
        amenity: "Across the road from Chesterman Beach and a short drive to the village of Tofino"
      },
      {
        id: 5,
        amenity: "Indoor and outdoor hot/cold showers"
      },
      {
        id: 6,
        amenity: "Optik TV/stereo music, TV with full range of channels including Netflix"
      },
      {
        id: 7,
        amenity: "BBQ and Lounging Chairs in the solarium"
      },
      {
        id: 8,
        amenity: "Relax during your Tofino vacation in the clawfoot soaker tub in the solarium"
      },
      {
        id: 9,
        amenity: "Full Kitchen"
      },
      {
        id: 10,
        amenity: "Dishwasher"
      },
      {
        id: 11,
        amenity: "Washer & Dryer"
      }
    ]
  },
]