import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import CabinInformation from '../elements/cabinInformation'
import {shipwreckData} from "../data/shipwreckData"

const Shipwreck = () => {

const imagesQuery = useStaticQuery(graphql`
  query {
    sliderImages: allFile(filter: {relativeDirectory: {eq: "shipwreck"}, ext: {regex: "/(jpg)|(png)|(jpeg)/"}}, sort: {fields: relativePath, order: ASC}) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`)


  return (
    <>
      <CabinInformation images={imagesQuery} data={shipwreckData} title="Shipwreck Cabin - Studio"/>
    </>
  )
}

export default Shipwreck
