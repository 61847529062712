import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import CabinsFullWidth from "../components/cabinsFullWidth"
import Shipwreck from "../components/shipwreck"
import ContactInfo from "../components/contactInfo"

const ShipwreckCabin = () => {
  return (
    <Layout>
      <SEO title="Shipwreck Cabin" />
      <Shipwreck />
      <ContactInfo />
      <CabinsFullWidth/>
    </Layout>
  )
}

export default ShipwreckCabin
